import { HTMLAttributes, PropsWithChildren, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { useBreakpoint } from "@/hooks/use-breakpoint";
import { MenuItem } from "@/components/navigations/menu-item";
import { useAppContext } from "@/contexts/app-context";

import ss4kLogo from "@/assets/ss4k_logo.gif";

type MenuPageProps = HTMLAttributes<HTMLDivElement> & {
  title?: string;
};

const MenuPageLayout = ({
  title,
  className,
  children,
}: PropsWithChildren<MenuPageProps>) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    state: { childAuthorizations, isLoadingSelectedChildId, selectedChildId },
    handlers: { handleSelectChildId },
  } = useAppContext();

  const isTabletOrDesktop = useBreakpoint("lg");
  const isMobile = !isTabletOrDesktop;

  return (
    <IonPage>
      <IonContent
        fullscreen
        className="bg-fixed bg-center bg-cover bg-[url('/assets/images/menu-layout-background.jpg')]"
      >
        <div className="h-full bg-transparent overflow-y-scroll">
          <div className="container mx-auto p-4 flex flex-col gap-4">
            <div className="flex gap-4 h-[100px] items-center justify-between">
              <div className="h-full w-[200px] flex justify-start">
                {isTabletOrDesktop ? (
                  <img src={ss4kLogo} className="relative h-full w-auto" />
                ) : (
                  <button
                    className="relative rounded-lg p-2 hover:bg-primary-blue-500"
                    onClick={() => setMenuOpen(true)}
                  >
                    <img src={ss4kLogo} className="relative h-full w-auto" />
                    <FontAwesomeIcon
                      icon="bars"
                      size="lg"
                      className="text-gray-500 absolute bottom-3 left-6"
                    />
                  </button>
                )}
              </div>

              <nav
                className={clsx("grow lg:flex gap-x-4 lg:justify-center z-10", {
                  "flex flex-col fixed inset-2 bg-white border shadow rounded-lg p-2 transition-transform":
                    isMobile,
                  "translate-y-[-110%]": isMobile && !menuOpen,
                  "translate-0": isMobile && menuOpen,
                })}
              >
                {isMobile && (
                  <div className="flex justify-between items-center border-b ps-2 pb-2 mb-2">
                    <h2 className="text-2xl">Menu</h2>

                    <button
                      onClick={() => setMenuOpen(false)}
                      className="btn-default"
                    >
                      <FontAwesomeIcon icon="times" />
                    </button>
                  </div>
                )}

                <MenuItem to="/songs" label="Songs" icon="music" />
                <MenuItem to="/vocabulary" label="Vocabulary" icon="comment" />
                <MenuItem to="/settings" label="Settings" icon="cog" />
                <MenuItem to="/account" label="Account" icon="user" />
                <MenuItem to="/help" label="Help" icon="question" />
                <MenuItem
                  to="/sign-out"
                  label="Sign Out"
                  icon="right-from-bracket"
                />
              </nav>

              <div className="w-[200px] flex justify-end">
                {!isLoadingSelectedChildId && (
                  <select
                    className="rounded-lg"
                    onChange={handleSelectChildId}
                    value={selectedChildId}
                  >
                    <option value="">Select Child</option>
                    {childAuthorizations.map((childAuthorization) => (
                      <option
                        key={childAuthorization.id}
                        value={childAuthorization.child.id}
                      >
                        {childAuthorization.child.fullName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="rounded-3xl border-4 border-amber-400 bg-amber-200 p-4 min-w-[200px] w-full lg:w-1/3 mx-auto font-heading text-center text-4xl font-medium">
              {title}
            </div>

            <div
              className={clsx(
                "rounded-3xl border-4 border-amber-400 bg-white px-4 py-8 flex justify-center",
                className,
              )}
            >
              {children}
            </div>

            <footer className="grid grid-cols-4 gap-4">
              <a
                href="https://singandspeak4kids.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="link text-[unset] text-center"
              >
                Privacy Policy
              </a>

              <a
                href="https://singandspeak4kids.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
                className="link text-[unset] text-center"
              >
                Terms of Service
              </a>

              <div className="text-center">
                &copy; {new Date().getFullYear()}{" "}
                <a
                  href="https://singandspeak4kids.com/about-us"
                  target="_blank"
                  rel="noreferrer"
                  className="link text-[unset]"
                >
                  iQsonics LLC
                </a>
              </div>

              <p className="text-center">All Rights Reserved</p>
            </footer>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MenuPageLayout;
