import { snakeCase, camelCase } from "lodash";

const transformKeys = <T = unknown>(
  obj: T,
  transformFn: (key: string) => string,
): T => {
  if (Array.isArray(obj)) {
    return obj.map((v: unknown): unknown => transformKeys(v, transformFn)) as T;
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [transformFn(key)]: transformKeys(
          (obj as Record<string, unknown>)[key],
          transformFn,
        ),
      }),
      {} as T,
    );
  }

  return obj as T;
};

export const underscoreKeys = <T>(obj: T): T =>
  transformKeys<T>(obj, snakeCase);
export const camelizeKeys = <T>(obj: T): T => transformKeys<T>(obj, camelCase);
