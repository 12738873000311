import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  loading?: boolean;
  type: HTMLButtonElement["type"];
};

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  loading = false,
  disabled = false,
  ...props
}) => {
  return (
    <button disabled={disabled || loading} {...props}>
      {loading ? <FontAwesomeIcon icon="circle-notch" spin /> : children}
    </button>
  );
};
