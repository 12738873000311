import { Link, LinkProps, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "flowbite-react";
import clsx from "clsx";

import { useBreakpoint } from "@/hooks/use-breakpoint";
import { PropsWithChildren, ReactNode } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type MenuItemProps = LinkProps & {
  label: ReactNode;
  icon: IconProp;
};

export const MenuItem = ({ to, label, icon }: MenuItemProps) => {
  const isActive = useRouteMatch(to as string);
  const isTabletOrDesktop = useBreakpoint("lg");
  const isMobile = !isTabletOrDesktop;

  const Wrapper = ({ children }: PropsWithChildren) => {
    return isMobile ? (
      <>{children}</>
    ) : (
      <Tooltip content={label}>{children}</Tooltip>
    );
  };

  return (
    <Wrapper>
      <Link
        to={to}
        className={clsx({
          "flex gap-4 items-center hover:bg-gray-200 rounded-lg p-2": isMobile,
        })}
        replace
      >
        <div
          className={clsx(
            "!size-16 p-4 ring-2 ring-white text-xl flex items-center justify-center",
            {
              "btn-primary": !isActive,
              "btn-secondary": isActive,
            },
          )}
        >
          <FontAwesomeIcon icon={icon} />
        </div>

        {isMobile && <span className="text-xl">{label}</span>}
      </Link>
    </Wrapper>
  );
};
