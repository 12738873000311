import { useCallback, useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

type Breakpoint = keyof typeof tailwindConfig.theme.screens;

export const useBreakpoint = (breakpoint: Breakpoint) => {
  const [matches, setMatches] = useState(
    window.matchMedia(`(min-width: ${fullConfig.theme.screens[breakpoint]})`)
      .matches,
  );

  const listener = useCallback(() => {
    setMatches(
      window.matchMedia(`(min-width: ${fullConfig.theme.screens[breakpoint]})`)
        .matches,
    );
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [listener]);

  return matches;
};
