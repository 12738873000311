import { QueryClient, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { request } from "@/utils/request";
import { storage } from "@/utils/storage";
import { camelizeKeys } from "@/utils/object-functions";

type QueryResponse<T> = [T | undefined, { isLoading: boolean }];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      queryFn: async ({ queryKey }) => {
        const response = await request.get(queryKey[0] as string);

        if (response.status === 401) {
          await storage.remove("authToken");
        }

        return response.json();
      },
    },
  },
});

export const useResource = <T = unknown>(
  path: string,
  options?: Partial<UseQueryOptions<T>>,
): QueryResponse<T> => {
  const { data, isLoading } = useQuery<T>(
    {
      queryKey: [path],
      ...options,
    },
    queryClient,
  );

  return [camelizeKeys(data) as T, { isLoading }];
};

export const useCollection = <T = unknown>(
  path: string,
  options?: Partial<UseQueryOptions<Array<T>>>,
): QueryResponse<Array<T>> => {
  const { data, isLoading } = useQuery<Array<T>>(
    {
      queryKey: [path],
      ...options,
    },
    queryClient,
  );

  return [camelizeKeys(data) as Array<T>, { isLoading }];
};
