import { Button } from "@/components/button";
import MenuPageLayout from "@/components/layouts/MenuPageLayout";
import { Tabs, Tab } from "@/components/tabs";
import { useAppContext } from "@/contexts/app-context";
import { useResource } from "@/hooks/resources";
import ChildType from "@/types/child-type";
import UserType from "@/types/user-type";
import { request } from "@/utils/request";
import { useState } from "react";

const AccountPage = () => {
  const {
    state: { selectedChildId },
  } = useAppContext();

  const [currentUser] = useResource<UserType>("/users/me");

  const [selectedChild] = useResource<ChildType>(
    `/children/${selectedChildId}`,
    {
      enabled: !!selectedChildId && selectedChildId !== "",
    },
  );

  const [isLoadingBillingPortal, setIsLoadingBillingPortal] = useState(false);

  const handleStripeBillingPortal = async () => {
    setIsLoadingBillingPortal(true);

    const response = await request.post<{ redirectUrl: string }>(
      "/stripe/billing_portal/sessions",
    );

    if (response.ok && response?.data?.redirectUrl) {
      window.location.href = response.data.redirectUrl;
    }

    setIsLoadingBillingPortal(false);
  };

  return (
    <MenuPageLayout title="My Account">
      <div className="flex flex-col gap-12 w-full max-w-screen-lg">
        <Tabs>
          <Tab label="Account Info" value="account">
            <div className="p-4 flex flex-col gap-8">
              <section className="flex flex-col gap-4">
                <h2 className="text-2xl pb-4 border-b">My Account</h2>

                <div className="grid lg:grid-cols-[150px,1fr] gap-x-4 gap-y-2 items-center">
                  <label className="font-semibold lg:text-right">Name</label>
                  <p>{currentUser?.fullName}</p>

                  <label className="font-semibold lg:text-right">Email</label>
                  <p>{currentUser?.email}</p>

                  <label className="font-semibold lg:text-right">Phone</label>
                  <p>{currentUser?.phoneNumber}</p>
                </div>
              </section>

              <section className="flex flex-col gap-4">
                <h2 className="text-2xl pb-4 border-b">Membership</h2>

                <div className="grid lg:grid-cols-[150px,1fr] gap-x-4 gap-y-2 items-center">
                  <label className="font-semibold lg:text-right">Tier</label>
                  <p>
                    {
                      currentUser?.subscriptions?.[0]?.productPrices?.[0]
                        ?.product?.name
                    }
                  </p>
                </div>

                {currentUser?.stripeCustomerId && (
                  <div className="grid lg:grid-cols-[150px,1fr] gap-x-4 gap-y-2 items-center">
                    <div></div>
                    <div>
                      <Button
                        type="button"
                        onClick={handleStripeBillingPortal}
                        className="btn btn-secondary px-6"
                        loading={isLoadingBillingPortal}
                      >
                        Manage Billing
                      </Button>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </Tab>

          {currentUser?.role === "parent" && (
            <Tab label="Child Info" value="children">
              <div className="p-4 flex flex-col gap-4">
                <h2 className="text-2xl pb-4 border-b">Child Information</h2>

                <div className="grid lg:grid-cols-[150px,1fr] gap-x-4 gap-y-2 items-center">
                  <label className="font-semibold lg:text-right">
                    Child&apos;s Name
                  </label>
                  <p>{selectedChild?.fullName}</p>

                  <label className="font-semibold lg:text-right">
                    Age Range
                  </label>
                  <p>{selectedChild?.ageRange}</p>
                </div>

                {/* <hr />

                  <button className="btn btn-secondary">Manage Membership</button> */}
              </div>
            </Tab>
          )}

          <Tab label="Support / Feedback" value="feedback">
            <div className="p-4 flex flex-col gap-2">
              <h2 className="text-2xl">
                We&apos;d love to hear how things are going.
              </h2>

              <p>
                Any questions? Email us at{" "}
                <a href="mailto:SS4KidsSupport@iqsonics.com" className="link">
                  SS4KidsSupport@iqsonics.com
                </a>
              </p>

              <p>
                Please take a moment and give us your feedback. Anything from
                likes or dislikes or even a suggestion for a feature are
                welcome!
              </p>
            </div>
          </Tab>
        </Tabs>
      </div>
    </MenuPageLayout>
  );
};

export default AccountPage;
