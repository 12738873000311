import { storage } from "@/utils/storage";
import { camelizeKeys, underscoreKeys } from "@/utils/object-functions";

class Response<T = Record<string, unknown>> extends globalThis.Response {
  data?: T;

  message?: string;
  errors?: Record<string, string[]>;
}

const getAuthenticationHeaders = async () => {
  const token = await storage.get("authToken");

  return {
    Authorization: token
      ? `Bearer ${token}`
      : `Basic ${btoa(import.meta.env.VITE_API_CLIENT_KEY + ":" + import.meta.env.VITE_API_CLIENT_SECRET)}`,
  };
};

export const request = {
  fetch: async (path: string, options?: RequestInit) => {
    const authenticationHeaders = await getAuthenticationHeaders();

    return fetch(import.meta.env.VITE_API_BASE_URL + path, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...authenticationHeaders,
        ...(options ? options.headers : {}),
      },
    });
  },

  get: async (path: string) => {
    return request.fetch(path);
  },

  post: async <T>(path: string, body?: unknown) => {
    const response = (await request.fetch(path, {
      method: "POST",
      body: JSON.stringify(underscoreKeys(body)),
    })) as Response<T>;

    if (response.ok) {
      response.data = camelizeKeys(await response.json());
    } else {
      const json = camelizeKeys(await response.json())

      response.message = json.message;
      response.errors = json.errors;
    }

    return response;
  },

  put: async <T>(path: string, body: unknown) => {
    const response = (await request.fetch(path, {
      method: "PUT",
      body: JSON.stringify(underscoreKeys(body)),
    })) as Response<T>;

    if (response.ok) {
      response.data = camelizeKeys(await response.json());
    } else {
      response.errors = camelizeKeys(await response.json()).errors;
    }

    return response;
  },
};
