import type { Config } from "tailwindcss";

import tailwindFormPlugin from "@tailwindcss/forms";
import * as flowbite from "flowbite-react/tailwind";

export default {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}", flowbite.content()],
  theme: {
    screens: {
      sm: "640px",
      // => @media (min-width: 640px) { ... }

      md: "768px",
      // => @media (min-width: 768px) { ... }

      lg: "1024px",
      // => @media (min-width: 1024px) { ... }

      xl: "1280px",
      // => @media (min-width: 1280px) { ... }
    },
    extend: {
      fontFamily: {
        sans: ["Open Sans", "sans-serif"],
        heading: ["Coming Soon", "sans-serif"],
      },
      colors: {
        "primary-blue": {
          50: "#f0f7ff",
          100: "#e1effd",
          200: "#bcddfb",
          300: "#80c3f9",
          400: "#3da4f3",
          500: "#148ae5",
          600: "#076bc2",
          700: "#07559d",
          800: "#0a4982",
          900: "#0f3e6b",
          950: "#0a2747",
        },
        "primary-orange": {
          50: "#fff9ed",
          100: "#fff3d4",
          200: "#ffe2a9",
          300: "#ffcd72",
          400: "#feac39",
          500: "#fc8e0c",
          600: "#ed7509",
          700: "#c4590a",
          800: "#9c4510",
          900: "#7d3a11",
          950: "#441c06",
        },
        "primary-green": {
          50: "#f1fcf1",
          100: "#defae1",
          200: "#bff3c5",
          300: "#8de899",
          400: "#54d465",
          500: "#2dba40",
          600: "#209e31",
          700: "#1c7929",
          800: "#1b6025",
          900: "#184f21",
          950: "#082b0e",
        },
        borderOpacity: {
          DEFAULT: "0.125",
        },
      },
    },
  },
  plugins: [tailwindFormPlugin, flowbite.plugin()],
} satisfies Config;
