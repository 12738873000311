import clsx from "clsx";
import React, { ReactNode, useState } from "react";

type TabsProps = React.PropsWithChildren<{ current?: string | number }>;

export const Tabs = (props: TabsProps) => {
  const firstTab = React.Children.toArray(
    props.children,
  )[0] as React.ReactElement<TabProps>;

  const [current, setCurrent] = useState(props.current || firstTab.props.value);

  return (
    <section className="grow">
      <ul className="flex overflow-x-auto text-sm font-semibold text-center text-gray-500 border-b border-gray-200">
        {React.Children.map(props.children, (child) => {
          const item = child as React.ReactElement<TabProps>;

          if (item && item.type === Tab) {
            return (
              <li className="me-2">
                <button
                  onClick={() => setCurrent(item.props.value)}
                  className={clsx(
                    "inline-block p-4 px-6 border border-b-0 border-solid border-gray-200 rounded-t-lg whitespace-nowrap",
                    {
                      "hover:text-gray-600 hover:bg-gray-50":
                        item.props.value !== current,
                      "text-white bg-blue-400 hover:bg-blue-500":
                        item.props.value === current,
                    },
                  )}
                >
                  {item.props.label}
                </button>
              </li>
            );
          }
        })}
      </ul>

      <div className="border border-t-0 rounded-b-lg overflow-clip min-h-[400px]">
        {React.Children.map(props.children, (child) => {
          const item = child as React.ReactElement<TabProps>;

          if (item && (item.type !== Tab || item.props.value === current)) {
            return item;
          }
        })}
      </div>
    </section>
  );
};

type TabProps = React.PropsWithChildren<{
  label: ReactNode;
  value: string | number;
}>;

export const Tab = ({ children }: TabProps) => {
  return children;
};
