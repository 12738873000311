import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ImmerReducer, useImmerReducer } from "use-immer";

import { queryClient, useCollection, useResource } from "@/hooks/resources";
import { storage } from "@/utils/storage";
import ChildAuthorizationType from "@/types/child-authorization-type";
import UserType from "@/types/user-type";

type AppContextType = {
  state: AppState;
  handlers: AppHandlers;
  actions: Record<keyof typeof actions, (value: string) => Promise<void>>;
};

const AppContext = createContext<AppContextType | null>(null);

type AppState = ReducerState & {
  isLoading: boolean;
  childAuthorizations: ChildAuthorizationType[];
  isLoadingChildAuthorizations: boolean;
  currentUser?: UserType;
  isLoadingCurrentUser: boolean;
  hasActiveSubscription: boolean;
};

type ReducerState = {
  authToken?: string;
  isLoadingAuthToken: boolean;
  selectedChildId: string;
  isLoadingSelectedChildId: boolean;
};

type AppHandlers = {
  handleSelectChildId: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const actions = {
  setAuthToken: "set_auth_token",
  setSelectedChildId: "set_selected_child_id",
} as const;

type AppAction = {
  type:
    | (typeof actions)[keyof typeof actions]
    | "set_initial_values_from_storage";
  payload?: unknown;
};

export const AppProvider = ({ children }: PropsWithChildren) => {
  const reducer: ImmerReducer<ReducerState, AppAction> = (state, action) => {
    switch (action.type) {
      case actions.setAuthToken:
        state.authToken = action.payload as string;
        break;

      case actions.setSelectedChildId:
        state.selectedChildId = action.payload as string;
        break;

      case "set_initial_values_from_storage": {
        const { selectedChildId, authToken } = action.payload as {
          selectedChildId: string;
          authToken: string;
        };

        state.selectedChildId ??= selectedChildId;
        state.authToken = authToken;
        state.isLoadingAuthToken = false;
        state.isLoadingSelectedChildId = false;
        break;
      }
    }

    return state;
  };

  const [state, dispatch] = useImmerReducer(reducer, {
    selectedChildId: "",
    isLoadingAuthToken: true,
    isLoadingSelectedChildId: true,
  });

  const [childAuthorizations, { isLoading: isLoadingChildAuthorizations }] =
    useCollection<ChildAuthorizationType>("/child_authorizations", {
      refetchOnMount: false,
      enabled: !!state.authToken,
    });

  const [currentUser, { isLoading: isLoadingCurrentUser }] =
    useResource<UserType>("/users/me", {
      refetchOnMount: false,
      enabled: !!state.authToken,
    });

  const handleSelectChildId = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedChildId(event.target.value);
  };

  const setInitialValuesFromStorage = useCallback(async () => {
    const selectedChildId = await storage.get("selectedChildId");
    const authToken = await storage.get("authToken");

    dispatch({
      type: "set_initial_values_from_storage",
      payload: { selectedChildId, authToken },
    });
  }, [dispatch]);

  useEffect(() => {
    setInitialValuesFromStorage();
  }, [setInitialValuesFromStorage]);

  const setAuthToken = async (authToken: string) => {
    await storage.set("authToken", authToken);

    dispatch({ type: actions.setAuthToken, payload: authToken });
  };

  const setSelectedChildId = async (childId: string) => {
    await storage.set("selectedChildId", childId);

    dispatch({ type: actions.setSelectedChildId, payload: childId });
  };

  const isLoading = useMemo(
    () =>
      state.isLoadingAuthToken ||
      state.isLoadingSelectedChildId ||
      isLoadingChildAuthorizations,
    [
      state.isLoadingAuthToken,
      state.isLoadingSelectedChildId,
      isLoadingChildAuthorizations,
    ],
  );

  const hasActiveSubscription = useMemo(
    () =>
      currentUser?.subscriptions?.some(
        (subscription) => subscription.status === "active",
      ) || false,
    [currentUser],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          state: {
            ...state,
            isLoading,
            childAuthorizations: childAuthorizations || [],
            isLoadingChildAuthorizations,
            currentUser,
            isLoadingCurrentUser,
            hasActiveSubscription,
          },
          handlers: {
            handleSelectChildId,
          },
          actions: {
            setAuthToken,
            setSelectedChildId,
          },
        }}
      >
        {children}
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  return appContext as AppContextType;
};
